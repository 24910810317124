import React from 'react';
import { motion } from 'framer-motion';

interface Sponsor {
  id: number;
  name: string;
  logo: string;
  text: string;
  link: string;
}

const sponsors: Sponsor[] = [
  {
    id: 1,
    name: "Home Delivery",
    logo: "/images/HomeDelivery.PNG",
    text: "Home Delivery ist ein langjähriges Mitglied der Gold-Blau Familie und bietet Servieces rund um die Küchenmontage an.",
    link: ""
  },
  {
    id: 2,
    name: "Autoglas R&R",
    logo: "/images/AutoglasR&R.PNG",
    text: "Über mehr als 10 Jahren unterstützt Autoglas R&R den Verein und bietet Servieces rund um das Autoglas an.",
    link: ""
  },
  {
    id: 3,
    name: "OG Digital",
    logo: "/images/ogdigital.jpg",
    text: "OG Digital ist ein treues Mitglied des Vereins und unterstützt den Verein in allen IT-Bereichen seit 2014.",
    link: "https://ogdigital.de"
  },
  {
    id: 4,
    name: "Home Delivery",
    logo: "/images/HomeDelivery.PNG",
    text: "Home Delivery ist ein langjähriges Mitglied der Gold-Blau Familie und bietet Servieces rund um die Küchenmontage an.",
    link: ""
  },
  {
    id: 5,
    name: "Autoglas R&R",
    logo: "/images/AutoglasR&R.PNG",
    text: "Über mehr als 10 Jahren unterstützt Autoglas R&R den Verein und bietet Servieces rund um das Autoglas an.",
    link: ""
  },
  {
    id: 6,
    name: "OG Digital",
    logo: "/images/ogdigital.jpg",
    text: "OG Digital ist ein treues Mitglied des Vereins und unterstützt den Verein in allen IT-Bereichen seit 2014.",
    link: "https://ogdigital.de"
  },
  {
    id: 7,
    name: "Home Delivery",
    logo: "/images/HomeDelivery.PNG",
    text: "Home Delivery ist ein langjähriges Mitglied der Gold-Blau Familie und bietet Servieces rund um die Küchenmontage an.",
    link: ""
  },
  {
    id: 8,
    name: "Autoglas R&R",
    logo: "/images/AutoglasR&R.PNG",
    text: "Über mehr als 10 Jahren unterstützt Autoglas R&R den Verein und bietet Servieces rund um das Autoglas an.",
    link: ""
  },
  {
    id: 9,
    name: "OG Digital",
    logo: "/images/ogdigital.jpg",
    text: "OG Digital ist ein treues Mitglied des Vereins und unterstützt den Verein in allen IT-Bereichen seit 2014.",
    link: "https://ogdigital.de"
  },
];

const SponsorCard = ({ name, logo, text, link }: Omit<Sponsor, 'id'>) => (
  <a 
    // href={link}
    // target="_blank"
    // rel="noopener noreferrer"
    className="flex-shrink-0 w-[300px] sm:w-[350px] md:w-[400px] h-[150px] sm:h-[180px] md:h-[200px] 
      bg-white/10 backdrop-blur-sm rounded-xl p-4 sm:p-6 mx-3 sm:mx-6
      border border-white/20 hover:border-white/40 transition-all duration-300 
      hover:transform hover:scale-105 cursor-pointer"
  >
    <div className="flex h-full gap-3 sm:gap-6">
      {/* Left side - Round image */}
      <div className="flex-shrink-0 w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 
        rounded-full overflow-hidden border-2 border-white/20">
        <img 
          src={logo} 
          alt={name} 
          className="w-full h-full object-cover"
        />
      </div>
      
      {/* Right side - Content */}
      <div className="flex flex-col justify-center">
        <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-white mb-2 sm:mb-3">{name}</h3>
        <p className="text-sm sm:text-base md:text-base text-white/80">{text}</p>
      </div>
    </div>
  </a>
);

export const Sponsors = () => {
  return (
    <div className="w-full overflow-hidden py-8 sm:py-12">
      <div className="relative">
        <motion.div
          className="flex"
          initial={{ x: 0 }}
          animate={{ 
            x: "-250%" 
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "reverse",
              duration: 30,
              ease: "linear",
              repeatDelay: 0
            },
          }}
        >
          <div className="flex">
            {sponsors.map((sponsor) => (
              <SponsorCard key={sponsor.id} {...sponsor} />
            ))}
            {sponsors.map((sponsor) => (
              <SponsorCard key={`${sponsor.id}-duplicate-1`} {...sponsor} />
            ))}
            {sponsors.map((sponsor) => (
              <SponsorCard key={`${sponsor.id}-duplicate-2`} {...sponsor} />
            ))}
            {sponsors.map((sponsor) => (
              <SponsorCard key={`${sponsor.id}-duplicate-3`} {...sponsor} />
            ))}
            {sponsors.map((sponsor) => (
              <SponsorCard key={`${sponsor.id}-duplicate-4`} {...sponsor} />
            ))}
               {sponsors.map((sponsor) => (
              <SponsorCard key={`${sponsor.id}-duplicate-5`} {...sponsor} />
            ))}
            {sponsors.map((sponsor) => (
              <SponsorCard key={`${sponsor.id}-duplicate-6`} {...sponsor} />
            ))}
            {sponsors.map((sponsor) => (
              <SponsorCard key={`${sponsor.id}-duplicate-7`} {...sponsor} />
            ))}
            {sponsors.map((sponsor) => (
              <SponsorCard key={`${sponsor.id}-duplicate-8`} {...sponsor} />
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}; 