import '@fontsource/inter';
import logo from './logo.svg';
import './App.css';
import './index.css';
import { useEffect, useState } from 'react';
import { BentoGridDemo } from './components/Grid.tsx';
import { FloatingNavDemo } from './components/Navbar.tsx';
import { Routes, Route } from 'react-router-dom';
import Football from './pages/Football.tsx';
import Impressum from './pages/Impressum.tsx';
import Kontakt from './pages/Kontakt.tsx';
import Datenschutz from './pages/Datenschutz.tsx';
import { Layout } from './components/Layout.tsx';
import { ModelViewer } from './components/ModelViewer.tsx';
import { TextGenerateEffect } from './components/TextEffect.tsx';
import { CalendarView } from './components/Calendar.tsx';
import { Vorstand } from './components/Vorstand.tsx';
import { Footer } from './components/Footer.tsx';
import { Sponsors } from './components/Sponsors.tsx';
import { Loader } from './components/Loader.tsx';
import Volleyball from './pages/Volleyball.tsx';
import Schwimmen from './pages/Schwimmen.tsx';
import Icehockey from './pages/Icehockey.tsx';
import Judo from './pages/Judo.tsx';
import Tanzen from './pages/Tanzen.tsx';
import Karate from './pages/Karate.tsx';
import Fitmom from './pages/Fitmom.tsx';
import Gewichtheben from './pages/Gewichtheben.tsx';
import Sporttanz from './pages/Sporttanz.tsx';
import { DepartmentViewer } from './components/DepartmentViewer.tsx';
// Import other pages...

function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [color1, setColor1] = useState('#8080ff');
  const [color2, setColor2] = useState('#000080');
  const [degree, setDegree] = useState(141);
  const [showColorPicker, setShowColorPicker] = useState(true);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [initialModelLoaded, setInitialModelLoaded] = useState(false);
  const [loadedModelsCount, setLoadedModelsCount] = useState(0);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const desktopTotalModels = 1 ; // 9 sport models + 1 logo
  const mobileTotalModels = 1 + 1;  // 1 sport model + 1 logo
  const totalModels = isMobile ? mobileTotalModels : desktopTotalModels;
  const totalModels3 = 19 + 1;
  const totalModels2 = 19 + 1;
  const totalModels1 = isMobile ? totalModels3 : totalModels2;

  const isLoading = false // (!modelsLoaded || !initialModelLoaded) && loadedModelsCount < totalModels1;
  const words = "Über 350 Mitglieder sind bereits von uns überzeugt. Sie sorgen dafür, dass wir ein mittelständiger Sportverein in Raum Augsburg, sind. Als traditionsreicher Verein stellen wir uns auch den Herausforderungen der Zeit: Von Judo über Fußball und Eishockey, Gewichtheben, Karate bis zu Volleyball bietet der SV Sportarten für alle Alterstufen. Vom Kleinkind bis zum Seniorenalter sind wir für Sie da.";
  // Base URL based on environment
  const baseURL = process.env.NODE_ENV === 'production' 
    ? 'https://gold-blau.vercel.app' 
    : 'http://localhost:3000';
  const [textColor1, setTextColor1] = useState('#4169E1');
  const [textColor2, setTextColor2] = useState('#FFD700');
  const [fontSize, setFontSize] = useState(64); // 4rem = 64px
  const [fontFamily, setFontFamily] = useState('Oswald');
  const fontOptions = [
    'Inter',
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Lato',
    'Poppins',
    'Source Sans Pro',
    'Nunito',
    'Ubuntu',
    'Raleway',
    'Work Sans',
    
    'Arial',
    'Helvetica',
    'Verdana',
    'Tahoma',
    'Calibri',
    'Segoe UI',
    
    'Times New Roman',
    'Georgia',
    'Playfair Display',
    'Merriweather',
    'Crimson Text',
    'Libre Baskerville',
    'Lora',
    'PT Serif',
    
    'Bebas Neue',
    'Oswald',
    'Anton',
    'Abril Fatface',
    'Righteous',
    
    'Dancing Script',
    'Pacifico',
    'Great Vibes',
    'Satisfy',
    'Caveat',
    
    'Roboto Mono',
    'Source Code Pro',
    'Fira Code',
    'IBM Plex Mono',
    'Courier New'
  ];
  const handleModelsLoaded = () => {
    setModelsLoaded(true);
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseURL}/api/test`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
        console.log(result);
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
      }
    };
    
    fetchData();
  }, [baseURL]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Update model loading handler
  useEffect(() => {
    const handleModelLoad = (event) => {
      const { modelPath, totalLoaded } = event.detail;
      const maxModels = isMobile ? mobileTotalModels : desktopTotalModels;
      
      if (totalLoaded <= maxModels) {
        setLoadedModelsCount(totalLoaded);
      }
    };

    window.addEventListener('modelLoaded', handleModelLoad);
    return () => {
      window.removeEventListener('modelLoaded', handleModelLoad);
    };
  }, [isMobile]);

  useEffect(() => {
   


    // audio.play().catch(() => {});
    // Listen for when all models in BentoGrid are loaded
    const handleAllModelsLoaded = () => {
      setModelsLoaded(true);
    };

    window.addEventListener('allModelsLoaded', handleAllModelsLoaded);
    return () => {
      window.removeEventListener('allModelsLoaded', handleAllModelsLoaded);
    };
  }, []);
  const handleInitialModelLoad = () => {
    setInitialModelLoaded(true);
    setLoadedModelsCount(prev => prev + 1);
  };

  useEffect(() => {
    const handleModelLoad = () => {
      setLoadedModelsCount(prev => prev + 1);
    };

    window.addEventListener('modelLoaded', handleModelLoad);
    return () => {
      window.removeEventListener('modelLoaded', handleModelLoad);
    };
  }, []);

  const [loadingQueue, setLoadingQueue] = useState([]);
  const maxConcurrentLoads = 1; // Adjust based on performance testing

  useEffect(() => {
    if (loadingQueue.length > 0 && loadedModelsCount < maxConcurrentLoads) {
      const modelToLoad = loadingQueue[0];
      setLoadingQueue(prev => prev.slice(1));
      // Load model logic here
    }
  }, [loadingQueue, loadedModelsCount]);

  return (
    <>
      {/* {(!modelsLoaded || !initialModelLoaded) && (
        <Loader 
          totalModels={totalModels}
          loadedModels={loadedModelsCount}
        />
      )} */}
    <div className="App w-full" style={{ fontFamily: 'Inter, sans-serif' }}>
      <Routes>
        <Route path="/" element={
          <Layout color1={color1} color2={color2} degree={degree} isLoading={isLoading} style={{ 
            zIndex: 1,
            width: '100%'
          }}>
            <h1 
              className="heading" 
              style={{ 
                fontFamily: fontFamily + ', sans-serif',
                textAlign: 'center',
                fontSize: `${fontSize}px`,
                margin: '2rem 0',
                fontWeight: 'bold',
                display: 'block'
              }}
            >
              <span style={{
                display: 'inline-block',
                background: 'transparent',
                backgroundImage: `linear-gradient(to right, ${textColor1}, ${textColor2})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
                padding: '0.1em 0'
              }}>
                Herzlich <span>Willkommen</span>
              </span>
            </h1>
            {/* {showColorPicker && (
              <div className="container mx-auto px-4">
                <div className="gradient-picker w-full" style={{ 
                  padding: '20px', 
                  textAlign: 'center',
                  marginTop: '100px',
                  position: 'relative'
                }}>
                  <button 
                    onClick={() => setShowColorPicker(false)}
                    style={{
                      position: 'absolute',
                      right: '20px',
                      top: '20px',
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontSize: '20px',
                      cursor: 'pointer',
                      padding: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      transition: 'background-color 0.2s'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                  >
                    ×
                  </button>

                  <div className="flex flex-wrap justify-center gap-4 w-full max-w-full mb-6">
                    <label className="text-white">
                      Color 1:
                      <input
                        type="color"
                        value={color1}
                        onChange={(e) => setColor1(e.target.value)}
                        className="ml-2"
                      />
                    </label>
                    <label className="text-white">
                      Color 2:
                      <input
                        type="color"
                        value={color2}
                        onChange={(e) => setColor2(e.target.value)}
                        className="ml-2"
                      />
                    </label>
                  </div>

                  <div className="w-full max-w-full mb-6">
                    <label className="text-white flex items-center justify-center gap-2">
                      Gradient Angle: {degree}°
                      <input
                        type="range"
                        min="0"
                        max="360"
                        value={degree}
                        onChange={(e) => setDegree(Number(e.target.value))}
                        className="w-48"
                      />
                    </label>
                  </div>
                  
                  <div className="w-full max-w-full p-4 rounded-lg bg-white/10 mb-6">
                    <h3 className="text-white mb-2">Current Gradient CSS:</h3>
                    <code className="text-white">
                      background: linear-gradient({degree}deg, {color1}, {color2});
                    </code>
                  </div>

                  <div className="w-full max-w-full border-t border-white/20 pt-6">
                    <h3 className="text-white mb-4">Text Settings:</h3>
                    
                    <div className="flex flex-wrap justify-center gap-4 mb-6">
                      <label className="text-white">
                        Text Color 1:
                        <input
                          type="color"
                          value={textColor1}
                          onChange={(e) => setTextColor1(e.target.value)}
                          className="ml-2"
                        />
                      </label>
                      <label className="text-white">
                        Text Color 2:
                        <input
                          type="color"
                          value={textColor2}
                          onChange={(e) => setTextColor2(e.target.value)}
                          className="ml-2"
                        />
                      </label>
                    </div>

                    <div className="flex flex-wrap justify-center gap-4 mb-6">
                      <label className="text-white">
                        Font Size: {fontSize}px
                        <input
                          type="range"
                          min="16"
                          max="128"
                          value={fontSize}
                          onChange={(e) => setFontSize(Number(e.target.value))}
                          className="w-48"
                        />
                      </label>
                    </div>

                    <div className="flex flex-wrap justify-center gap-4 mb-6">
                      <label className="text-white">
                        Font Family:
                        <select
                          value={fontFamily}
                          onChange={(e) => setFontFamily(e.target.value)}
                          className="w-48"
                        >
                          <optgroup label="Modern Sans-Serif">
                            {fontOptions.slice(0, 11).map((font) => (
                              <option key={font} value={font}>{font}</option>
                            ))}
                          </optgroup>
                          <optgroup label="Classic Sans-Serif">
                            {fontOptions.slice(11, 17).map((font) => (
                              <option key={font} value={font}>{font}</option>
                            ))}
                          </optgroup>
                          <optgroup label="Serif">
                            {fontOptions.slice(17, 25).map((font) => (
                              <option key={font} value={font}>{font}</option>
                            ))}
                          </optgroup>
                          <optgroup label="Display">
                            {fontOptions.slice(25, 30).map((font) => (
                              <option key={font} value={font}>{font}</option>
                            ))}
                          </optgroup>
                          <optgroup label="Handwriting/Script">
                            {fontOptions.slice(30, 35).map((font) => (
                              <option key={font} value={font}>{font}</option>
                            ))}
                          </optgroup>
                          <optgroup label="Monospace">
                            {fontOptions.slice(35).map((font) => (
                              <option key={font} value={font}>{font}</option>
                            ))}
                          </optgroup>
                        </select>
                      </label>
                    </div>

                    <div className="w-full max-w-full p-4 rounded-lg bg-white/10 mb-6">
                      <h3 className="text-white mb-2">Current Text CSS:</h3>
                      <code className="text-white">
                        background: linear-gradient(to right, {textColor1}, {textColor2});<br/>
                        -webkit-background-clip: text;<br/>
                        background-clip: text;<br/>
                        color: transparent;<br/>
                        font-size: {fontSize}px;<br/>
                        font-family: {fontFamily}, sans-serif;
                      </code>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
         <ModelViewer modelPath="/models/GBLogo.glb" onLoad={handleInitialModelLoad} /> 
            <TextGenerateEffect
            words={words}
            className="text-justify text-[15px] md:text-xl lg:text-2xl  fontFamily:Oswald"
          />
                      <h1 
              className="heading" 
              id="Abteilungen"
              style={{ 
                fontFamily: fontFamily + ', sans-serif',
                textAlign: 'center',
                fontSize: `${fontSize}px`,
                margin: '2rem 0',
                fontWeight: 'bold',
                display: 'block'
              }}
            >
             <span style={{
                display: 'inline-block',
                background: 'transparent',
                backgroundImage: `linear-gradient(to right, ${textColor1}, ${textColor2})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
                padding: '0.1em 0'
              }}>
                Abteilungen
              </span>
            </h1>
            <DepartmentViewer />
            {/* <BentoGridDemo /> */}
            <h1 
              className="heading" 
              id="Termine"
              style={{ 
                fontFamily: fontFamily + ', sans-serif',
                textAlign: 'center',
                fontSize: `${fontSize}px`,
                margin: '2rem 0',
                fontWeight: 'bold',
                display: 'block'
              }}
            >
             <span style={{
                display: 'inline-block',
                background: 'transparent',
                backgroundImage: `linear-gradient(to right, ${textColor1}, ${textColor2})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
                padding: '0.1em 0'
              }}>
                Termine
              </span>
            </h1>
            <CalendarView />
            <h1 
              className="heading"
              id="Vorstand"
              style={{ 
                fontFamily: fontFamily + ', sans-serif',
                textAlign: 'center',
                fontSize: `${fontSize}px`,
                margin: '2rem 0',
                fontWeight: 'bold',
                display: 'block'
              }}
            >
             <span style={{
                display: 'inline-block',
                background: 'transparent',
                backgroundImage: `linear-gradient(to right, ${textColor1}, ${textColor2})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
                padding: '0.1em 0'
              }}>
                Vorstand
              </span>
            </h1>
            <Vorstand />
            <h1 
              className="heading"
              id="Sponsoren"
              style={{ 
                fontFamily: fontFamily + ', sans-serif',
                textAlign: 'center',
                fontSize: `${fontSize}px`,
                margin: '2rem 0',
                fontWeight: 'bold',
                display: 'block'
              }}
            >
             <span style={{
                display: 'inline-block',
                background: 'transparent',
                backgroundImage: `linear-gradient(to right, ${textColor1}, ${textColor2})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
                padding: '0.1em 0'
              }}>
                Sponsoren
              </span>
            </h1>
           <Sponsors /> 
            <Footer />
          </Layout>
        } />
        <Route path="/football" element={<Football />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/volleyball" element={<Volleyball />} />
        <Route path="/schwimmen" element={<Schwimmen />} />
        <Route path="/icehockey" element={<Icehockey />} />
        <Route path="/judo" element={<Judo />} />
        <Route path="/tanzen" element={<Tanzen />} />
        <Route path="/karate" element={<Karate />} />
        <Route path="/fitmom" element={<Fitmom />} />
        <Route path="/gewichtheben" element={<Gewichtheben />} />
        <Route path="/sporttanz" element={<Sporttanz />} />
        {/* Add other routes */}
      </Routes>
    </div>
    </>
  );
}

export default App;
