import React from 'react';

interface VorstandMember {
  name: string;
  position: string;
  image: string;
}

const vorstandMembers: VorstandMember[] = [
  {
    name: "Alexander Brot",
    position: "Vorstandsvorsitzender",
    image: "/images/playerO.jpg"
  },
  {
    name: "Andreas Kühn",
    position: "Zweiter Vorstand",
    image: "/images/player.jpg"
  },
  {
    name: "Denis Schnitzer",
    position: "Dritter Vorstand",
    image: "/images/Schnitzer.jpeg"
  }
];

export const Vorstand: React.FC = () => {
  return (
    <section className="w-full py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between gap-8 max-w-6xl mx-auto">
          {vorstandMembers.map((member, index) => (
            <div 
              key={index}
              className="flex-1 bg-transparent rounded-lg p-6 text-center transform transition-transform hover:scale-105"
            >
              <img
                src={member.image}
                alt={member.name}
                className="w-48 h-48 rounded-full mx-auto mb-4 object-cover"
              />
              <h3 className="text-xl font-semibold mb-2 text-white">{member.name}</h3>
              <p className="text-gray-300">{member.position}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}; 